import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertMessageService, LocalStorageService } from '@sbt-suite/components';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AutenticacaoService } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    MODE = environment.MODE;

    constructor(
        private _router: Router,
        private alertMessage: AlertMessageService,
        private _localStorage: LocalStorageService,
        private _autenticacaoService: AutenticacaoService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    this._clearSession();
                }

                const message = error?.message || error?.error?.message;
                this.alertMessage.mostrarAlerta(`Error: ${message}`);
                return throwError(() => error);
            })
        );
    }

    private _clearSession() {
        this._autenticacaoService.clearLocalStorage();
        this._autenticacaoService.setUsuarioAutenticado(false);
        this._router.navigateByUrl('/login');
    }
}
